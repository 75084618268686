import './_menupage.css';

import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useState } from 'react';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';

import trashIcon from './../images/Button_iconButton_delete.svg';
import penIcon from './../images/Button_iconButton_edit.svg';
import logo from './../images/MEDIMEET.png';


const MainPage = () => {
  const cookies = new Cookies();

  const ref = useRef(null);
  const roomName = useRef(null);
  const newName = useRef(null);
  
  const navigate  = useNavigate();
  let roomList = cookies.get('roomList');
  // console.log(roomList);
  if( roomList === undefined){
    roomList = [];
  } else {
    // roomList.filter((room) => 
    //   room.list !== ''
    // );


    // 清除 List 第一個會有 null 的bug
    if(roomList[0] !== null) {
      roomList.filter((room) => 
        room.list !== ''
      );
    }

    if(roomList[0] === null) {
      roomList.splice(0, 1);
    }

    roomList = [...new Set(roomList)];
    // console.log(roomList);
  }

  let [listComponent, setListComponent] = useState(roomList);
  const [open, setOpen] = useState(false);
  const [openNew, setOpenNew] = useState(false);
  const [listID, setListID] = useState(null);
  const cancelButtonRef = useRef(null);

  // Start Meeting
  const startMeeting = () => {
    if(roomList === undefined) {

      if(roomList !== '' && roomList !== null) {
        roomList = [roomList];
        cookies.set('roomList', roomList, { path: '/', maxAge: 31536000 });
      }
    } else {

      if(ref.current.value !== ''  && roomList !== null) {
        roomList.push(ref.current.value);
        cookies.set('roomList', roomList, { path: '/', maxAge: 31536000 });
      }
    }
    navigate ('/' + ref.current.value);
  };

  // Join Meeting
  const joinMeeting = (index) => {
    navigate ('/' + listComponent[index]);
  };

  // Add Room
  const addRoom = () => {
    const newList = [...listComponent];
    // console.log(roomList);
    if(newName.current.value !== '') {
      newList.push(newName.current.value);
      // [...new Set(newList)];
      setListComponent(newList);
      cookies.set('roomList', newList, { path: '/', maxAge: 31536000 });
      setOpenNew(false);
    } else {
      alert('Please fill up the room name.')
    }
  };

  // Delete Meeting 
  const deleteMeeting = (index) => {
    const newList = [...listComponent];
    newList.splice(index, 1);
    setListComponent(newList);
    cookies.set('roomList', newList, { path: '/', maxAge: 31536000 });
  };

  // Edit Room Name
  const editRoomName = () => {
    if(roomName.current.value !== '') {
    listComponent[listID] = roomName.current.value;
      setListComponent(listComponent);
      cookies.set('roomList', listComponent, { path: '/', maxAge: 31536000 });
      setOpen(false);
    } else {
      alert('Please fill up the room name.')
    }
  };

  // Toggle Modal
  const openModal = (index) => {
    setListID(index);
    setOpen(true);
  }
  const closeModal = () => {
    setOpen(false);
  }
  const openModalNew = () => {
    setOpenNew(true);
  }
  const closeModalNew = () => {
    setOpenNew(false);
  }


  return (
    <div className="menuPage">
      <header>
        <img src={ logo } alt={"logo"}></img>
      </header>
      <div className="main-container">
        <div className="main-box">
          <div className="box-text">Secure and high quality meetings for medicine.</div>
          <div className="main-wrapper">
            <input 
              type="text" 
              placeholder="Room Name"
              ref={ref}
              id="message"
              name="message"
              defaultValue=""
            />
            <span onClick={startMeeting} className="pointer">Start</span>
          </div>
        </div>
      </div>
      <div className="menu-container">
        <div className="menu-box">
          { 
            listComponent.map((list, index) => (
            <div key={index} className="room-list-box">
              <div className="list-box " onClick={() => joinMeeting(index)}>{list}</div>
                <div className="icon-box">
                  <button onClick={() => deleteMeeting(index)}>
                    <img src={trashIcon} alt={'delete'}></img>
                  </button>
                  <button onClick={() => openModal(index)}>
                    <img src={penIcon} alt={'edit'}></img>
                  </button>
                </div>
            </div>
          ))}
        </div>
      </div>
      <button className="add-room" onClick={() => openModalNew()}>ADD</button>

      <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 custom-bg bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 custom-width">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg text-left shadow-xl transition-all sm:my-6 sm:w-full sm:max-w-lg custom-bg-color">
                <div className="custom-bg-color px-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="custom-marg">
                    <div className="mt-3 text-center sm:mt-0">
                      <div className="custom-box">
                        <input 
                          type="text" 
                          placeholder="Room Name"
                          ref={roomName}
                          id="roomName"
                          name="roomName"
                          defaultValue=""
                        />
                      </div>
                      <div className="custom-dialog-button">
                        <button onClick={() => closeModal()}>Cancel</button>
                        <button onClick={() => editRoomName()}>Edit Room</button>
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
      </Transition.Root>
      {/*  */}
      <Transition.Root show={openNew} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpenNew}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 custom-bg bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 custom-width">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg text-left shadow-xl transition-all sm:my-6 sm:w-full sm:max-w-lg custom-bg-color">
                <div className="custom-bg-color px-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="custom-marg">
                    <div className="mt-3 text-center sm:mt-0">
                      <div className="custom-box">
                        <input 
                          type="text" 
                          placeholder="Room Name"
                          ref={newName}
                          id="newName"
                          name="newName"
                          defaultValue=""
                        />
                      </div>
                      <div className="custom-dialog-button">
                        <button onClick={() => closeModalNew()}>Cancel</button>
                        <button onClick={() => addRoom()}>Add Room</button>
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
      </Transition.Root>
    </div>
  );

}

export default MainPage;


