import './App.css';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import MeetingRoom from './views/MeetingRoom';
import MainPage from './views/MenuPage';

function App() {
  return (
    <div className="App">
      <div className="bg"></div>
        <Router>
          <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/:id" element={<MeetingRoom />} />
          </Routes>
        </Router>
    </div>
  );
}

export default App;
